import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { medicalSpecialtyList } from '../../../utils/medicalSpecialtyList';
import { professionalRoleList } from '../../../utils/professionalRoleList';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { Spacer } from '../../Common/styled/Spacer';
import { Form } from '../../Common/styled/Form';
import { DropzoneWithCrop } from '../../Common/DropzoneWithCrop/DropzoneWithCrop';
import Loading from '../../Common/Loading';
import { isEmpty } from '../../../utils/ObjectUtils';

import { GET_USER_FOR_EDITING } from '../../../graphql/queries';
import { UPDATE_USER } from '../../../graphql/mutations';
import useStyles from '../styled/UserFormMakeStyles';
import { TextFieldSelectUI } from '../../../componentsUI/TextFieldSelect';
import { TextFieldUI } from '../../../componentsUI/TextField';
import { ButtonUI } from '../../../componentsUI/Button';
import { ScrollableContainer } from '../../Common/ScrollableContainer';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { AlertUI } from '../../../componentsUI/Alert';

const HEALTH_PROFESSIONAL = 'HEALTH_PROFESSIONAL';

export const EditUser = ({ user }) => {
  const { loading, data } = useQuery(GET_USER_FOR_EDITING);
  const { t } = useTranslation();
  const [isHealthProfessional, setHealthProfessional] = useState(user.professionalRole === HEALTH_PROFESSIONAL);
  const [serverError, setServerError] = useState(false);
  const [file, setFile] = useState(null);
  const classes = useStyles();

  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER, {
    onCompleted() {
      toast(`${t('user.profile.updated')}...`, { className: 'toast-success' });
    },
  });

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      const userParams = {
        name: values.name,
        surname: values.surname,
        personalId: values.personalId,
        professionalRole: values.professionalRole,
        ...(isHealthProfessional && { specialty: values.specialty }),
        ...(isHealthProfessional && { subspecialty: values.subspecialty }),
        ...(isHealthProfessional && { collegiateNumber: values.collegiateNumber }),
      };
      if (file) userParams.profilePhoto = file;
      await updateUser({ variables: { userParams } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required.field')),
    surname: Yup.string()
      .required(t('required.field')),
    personalId: Yup.string()
      .nullable().required(t('required.field')),
    professionalRole: Yup.string()
      .required(t('required.field')),
    collegiateNumber: Yup.string()
      .nullable()
      .when('professionalRole', {
        is: (professionalRole) => professionalRole === HEALTH_PROFESSIONAL,
        then: Yup.string().required(t('required.field')),
      }),
    specialty: Yup.string()
      .nullable()
      .when('professionalRole', {
        is: (professionalRole) => professionalRole === HEALTH_PROFESSIONAL,
        then: Yup.string().required(t('required.field')),
      }),
    subspecialty: Yup.string()
      .nullable()
      // .when('professionalRole', {
      //   is: (professionalRole) => professionalRole === HEALTH_PROFESSIONAL,
      //   then: Yup.string().required(t('required.field')),
      // })
    ,
  });

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="edit.profile" />
      </Navbar>

      <Box className={classes.container}>
        <ScrollableContainer padding="1.25em 0 0">
          <Paper elevation={2} className={classes.wrapper}>
            {loading || isEmpty(data) ? (
              <Loading />
            ) : (
              <Formik initialValues={user} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {(props) => (
                  <Form autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} className={classes.root}>
                        <TextFieldUI name="name" label={t('name')} props={props} />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.root}>
                        <TextFieldUI name="surname" label={t('surname')} props={props} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} className={classes.root}>
                        <TextFieldUI name="personalId" label={t('personal.Id')} props={props} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextFieldSelectUI
                          name="professionalRole"
                          label="professional.role"
                          props={props}
                          options={professionalRoleList}
                          onClick={(value) => setHealthProfessional(value === HEALTH_PROFESSIONAL)}
                          disabled={props.values.professionalRole === null}
                          classes={classes}
                        />
                      </Grid>
                    </Grid>
                    {
                      !!isHealthProfessional && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className={classes.root}>
                              <TextFieldUI name="collegiateNumber" label={t('collegiate.number')} props={props} />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextFieldSelectUI
                                name="specialty"
                                label="specialty"
                                props={props}
                                options={medicalSpecialtyList}
                                disabled={props.values.professionalRole === null}
                                classes={classes}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.root}>
                              <TextFieldUI name="subspecialty" label={t('subspecialty')} props={props} />
                            </Grid>
                          </Grid>
                        </>
                      )
                    }
                    <Box className={classes.spacer} />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('profile.image')}</Typography>
                        <Spacer emptySibling />
                        <DropzoneWithCrop
                          setFile={setFile}
                          currentFileUrl={props.values.profilePhoto}
                          aspect={1 / 1}
                          type="profile"
                        />
                      </Grid>
                    </Grid>

                    {updating && <Loading />}
                    {serverError ? <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI> : null}

                    <Box className={classes.spacer} />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ButtonUI type="submit" variant="contained" color="primary" size="large">
                          {t('update')}
                        </ButtonUI>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
